import React from 'react';
import './Landing.css';

function Landing(){
    return(
        <div class="Landing">
        <div class="header">
    <nav>
      <a href="/about">About</a>
      <a href="/minecraft">Minecraft</a>
      <a href="/theboys">The Boys</a>
    </nav>
    </div>
    <div>
        <h1>welcome to cumsocks.wtf </h1>
        <h2>navigate using the header (currently WIP, only header item that works is Minecraft)</h2>
        <img src="https://thumbs.gfycat.com/EnchantedDimJohndory-max-1mb.gif"></img> 
        <footer>
        ©cumsocks.wtf 2023
        </footer>
    </div>
    </div>


    )
}


export default Landing;