import React from 'react';
import './Main.css';

function Main(){
    return(
        <div>
            <a  data-text="&nbsp;ENTER COMMUNITY&nbsp;" href="/login">&nbsp;ENTER COMMUNITY&nbsp;</a>
        </div>
    )
}


export default Main;