import React from 'react';
import './About.css';

function About(){
    return(
        <div class="About">
        <div class="header">
    <nav>
      <a href="/about">About</a>
      <a href="/minecraft">Minecraft</a>
      <a href="/theboys">The Boys</a>
    </nav>
    </div>
    <div>
        <h1>welcome to cumsocks.wtf </h1>
        <h2>navigate using the header</h2>
        <footer>
        ©cumsocks.wtf 2023
        </footer>
    </div>
    </div>


    )
}


export default About;